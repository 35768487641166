import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const MessageSentIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={17.222} baseHeight={17.223} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 17.222 17.223">
        <g id="Group_6603" data-name="Group 6603" transform="translate(-641.947 -540.111)">
          <path
            id="Path_1315"
            data-name="Path 1315"
            d="M761.89,365.135a1.5,1.5,0,0,1-.874-.872q-1.191-2.5-2.388-5c-.126-.261-.286-.5-.421-.739l9.8-9.8.036.061v.437c-.038.133-.071.268-.115.4q-2.418,7.259-4.838,14.518a1.374,1.374,0,0,1-.865.993Z"
            transform="translate(-108.877 192.199)"
            fill={color} />
          <path
            id="Path_1316"
            data-name="Path 1316"
            d="M723.848,343.14l.067.036-9.785,9.785c-1.881-.991-3.845-1.858-5.773-2.8a1.5,1.5,0,0,1-.857-.868v-.336a1.175,1.175,0,0,1,.648-.724,3.127,3.127,0,0,1,.357-.144q4.445-1.483,8.89-2.962,3.006-1,6.015-1.988Z"
            transform="translate(-65.553 196.971)"
            fill={color} />
          <path
            id="Path_1317"
            data-name="Path 1317"
            d="M727.544,409.614c.554.27,1.11.5,1.621.8a2.931,2.931,0,0,1,1.163,1.272c.214.449.427.9.649,1.364-.052.023-.1.047-.15.067-.963.391-1.925.784-2.889,1.17a1.582,1.582,0,0,1-.985.107,1.041,1.041,0,0,1-.757-1.287,12.752,12.752,0,0,1,.474-1.322C726.951,411.068,727.245,410.356,727.544,409.614Z"
            transform="translate(-81.495 140.177)"
            fill={color} />
        </g>
      </svg>

    )}
  </Icon>
);
