import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const PersonCheckIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={20} baseHeight={16} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 20 16">
        <path
          id="Icon_awesome-user-check"
          data-name="Icon awesome-user-check"
          d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM9.8,9H9.278A5.44,5.44,0,0,1,4.722,9H4.2A4.2,4.2,0,0,0,0,13.2v1.3A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V13.2A4.2,4.2,0,0,0,9.8,9ZM19.894,4.987l-.869-.878a.371.371,0,0,0-.525,0l-3.275,3.25L13.8,5.925a.371.371,0,0,0-.525,0l-.878.872a.371.371,0,0,0,0,.525L14.95,9.891a.371.371,0,0,0,.525,0l4.416-4.381A.373.373,0,0,0,19.894,4.987Z"
          fill={color} />
      </svg>
    )}
  </Icon>
);
