import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const MessageReceivedIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={17.3} baseHeight={17.336} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 17.37 17.336">
        <path
          id="Path_1285"
          data-name="Path 1285"
          d="M65.877,110.25a.627.627,0,0,1,.378.664c-.013,1.088-.005,2.177-.005,3.265v.2h.821c.215,0,.43.005.644,0a.513.513,0,0,1,.517.283.519.519,0,0,1-.083.583c-.286.378-.569.758-.854,1.136q-1.063,1.414-2.125,2.827a.541.541,0,0,1-.437.249.551.551,0,0,1-.465-.262q-1.259-1.679-2.521-3.355c-.149-.2-.3-.4-.447-.6a.518.518,0,0,1,.433-.867c.48.006.96,0,1.465,0V114.2c0-1.094.008-2.188-.005-3.282a.627.627,0,0,1,.378-.664Z"
          transform="translate(-56.039 -110.25)"
          fill={color}
        />
        <path
          id="Path_1286"
          data-name="Path 1286"
          d="M41,138.209l.168-.465,5.381,4.753-5.378,4.774L41,146.81Z"
          transform="translate(-41 -130.76)"
          fill={color} />
        <path
          id="Path_1287"
          data-name="Path 1287"
          d="M93.088,146.815l-.168.463L87.539,142.5l5.378-4.751.171.464Z"
          transform="translate(-75.718 -130.766)"
          fill={color} />
        <path
          id="Path_1288"
          data-name="Path 1288"
          d="M44.678,163.891l5.376-4.771,1.3,1.152a1.494,1.494,0,0,0,1.566.348,2.007,2.007,0,0,0,.6-.364c.433-.362.848-.746,1.286-1.136l5.377,4.771-.008.061a4.582,4.582,0,0,1-.622.086c-2.12.006-4.24,0-6.36,0q-3.934,0-7.869,0a4.767,4.767,0,0,1-.638-.085Z"
          transform="translate(-43.744 -146.707)"
          fill={color} />
        <path
          id="Path_1289"
          data-name="Path 1289"
          d="M60.054,134.659l-1.457,1.29q-2.918,2.582-5.836,5.164a.566.566,0,0,1-.9,0q-3.52-3.116-7.041-6.231c-.087-.077-.173-.155-.3-.272a3.54,3.54,0,0,1,.569-.1c1.1-.009,2.193-.007,3.289,0a.3.3,0,0,1,.2.11q1.231,1.626,2.454,3.259a1.513,1.513,0,0,0,2.046.469,1.954,1.954,0,0,0,.513-.481c.822-1.076,1.632-2.161,2.442-3.246a.259.259,0,0,1,.237-.118c1.063,0,2.125,0,3.188.007a4.221,4.221,0,0,1,.591.086Z"
          transform="translate(-43.624 -128.343)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
