import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const MessageBubblesIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={18.174} baseHeight={16.147} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 18.174 16.147">
        <path
          id="Icon_metro-bubbles"
          data-name="Icon metro-bubbles"
          d="M19.736,19.36A1.842,1.842,0,0,0,20.746,21v.251a3.756,3.756,0,0,1-3.238-1.137,5.072,5.072,0,0,1-1.306.17,4.314,4.314,0,0,1-4.544-4.039A4.314,4.314,0,0,1,16.2,12.211a4.314,4.314,0,0,1,4.544,4.039,3.726,3.726,0,0,1-.842,2.342A1.832,1.832,0,0,0,19.736,19.36ZM10.649,5.143c4.393,0,7.967,2.849,8.075,6.4a6.191,6.191,0,0,0-2.522-.529A6.012,6.012,0,0,0,12.193,12.5a4.984,4.984,0,0,0-1.733,3.752,4.841,4.841,0,0,0,.438,2.016c-.082,0-.165,0-.248,0a9.975,9.975,0,0,1-1.259-.08,7.867,7.867,0,0,1-5.808,2.092v-.425a3.213,3.213,0,0,0,2.019-2.6,3.047,3.047,0,0,0-.034-.453,6.179,6.179,0,0,1-2.995-5.1c0-3.625,3.616-6.563,8.077-6.563Z"
          transform="translate(-2.571 -5.143)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
