import moment from "moment";
import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { useQuery } from '../../hooks/useQuery';
import { ValidationFilterModel, FilterModel } from "../../utils/types";

const getFiltersFromQuery = (queryParams): ValidationFilterModel[] => {
  const filters_from_params: any = Array.from(queryParams).map(([k, v]) => {
    try {
      return ValidationFilterModel.parse(k, queryParams.get(k))
    } catch (e: unknown) {
      console.warn(`Parsing filter has failed. ${e}, ${k}: ${v}`)
      return null
    }
  })

  return filters_from_params.filter(x => x instanceof ValidationFilterModel)
}

const add_default_range = (filters) => {
  return [
    new FilterModel({
      category: 'range',
      text: 'Last 7 Days',
      value: {
        startDate: moment().subtract(7, 'days').startOf('day').format(),
        endDate: moment().endOf('day').format(),
      },
    }),
    ...filters
  ]
}

export const usableFilters = (filters: FilterModel[]) => {
  const usable_filters = filters.filter(x => x.value);
  if (!usable_filters.length && filters.length)
    return [];
  return usable_filters;
}

export function useFilter() {
  const Query = useQuery();
  const filters_from_query = getFiltersFromQuery(Query.queryParams);
  const [_filters, _setFilters] = useState<FilterModel[]>(
    (FilterModel.getCategory(filters_from_query, "range"))
    ? FilterModel.moveRangeToHead(filters_from_query)
    : add_default_range(filters_from_query)
  );

  useEffect(() => {
    Query.setQueryParams(_filters.map(x => ({ name: x.category, value: x.toString() })));
  }, [_filters])

  const setFilters = useCallback(newValue => {
    _setFilters(oldValue => {
      return newValue;
    });
  }, []);

  return { filters: _filters, setFilters };
}

export const getFilterValue = (filters: FilterModel[], category: string) => {
  const usable_filters = usableFilters(filters);

  for (var filter of usable_filters) {
    switch (filter.category) {
      case category:
        return filter.value;
    }
  }
  return null;
}
