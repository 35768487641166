import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const PeopleIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={21.429} baseHeight={15} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 21.429 15">
        <path
          id="Icon_awesome-users"
          data-name="Icon awesome-users"
          d="M3.214,8.679A2.143,2.143,0,1,0,1.071,6.536,2.145,2.145,0,0,0,3.214,8.679Zm15,0a2.143,2.143,0,1,0-2.143-2.143A2.145,2.145,0,0,0,18.214,8.679ZM19.286,9.75H17.143a2.137,2.137,0,0,0-1.51.623,4.9,4.9,0,0,1,2.515,3.663h2.21a1.07,1.07,0,0,0,1.071-1.071V11.893A2.145,2.145,0,0,0,19.286,9.75Zm-8.571,0A3.75,3.75,0,1,0,6.964,6,3.748,3.748,0,0,0,10.714,9.75Zm2.571,1.071h-.278a5.177,5.177,0,0,1-4.587,0H8.143a3.858,3.858,0,0,0-3.857,3.857v.964A1.608,1.608,0,0,0,5.893,17.25h9.643a1.608,1.608,0,0,0,1.607-1.607v-.964A3.858,3.858,0,0,0,13.286,10.821ZM5.8,10.373a2.137,2.137,0,0,0-1.51-.623H2.143A2.145,2.145,0,0,0,0,11.893v1.071a1.07,1.07,0,0,0,1.071,1.071H3.278A4.91,4.91,0,0,1,5.8,10.373Z"
          transform="translate(0 -2.25)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
