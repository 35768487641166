


import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const PhoneUniqueCallerIcon: FC<IIconProps> = (props) => (
    <Icon baseWidth={18.247} baseHeight={18.247} {...props}>
        {(width, height, color) => (
            <svg width={width} height={height} viewBox="0 0 18.247 18.247">
                <g id="Page-1" transform="translate(0 0.001)">
                    <g id="_003---Call" data-name="003---Call" transform="translate(0 -0.001)">
                        <path
                            id="Shape"
                            d="M8.561,10.743A7.372,7.372,0,0,1,6.335,6.557a.626.626,0,0,1,.18-.524l1.411-1.41a.63.63,0,0,0,.089-.779L5.768.356a.63.63,0,0,0-.816-.23l-3.606,1.7A.626.626,0,0,0,1,2.45,17.432,17.432,0,0,0,6.308,12.995,17.427,17.427,0,0,0,16.852,18.3a.626.626,0,0,0,.626-.346l1.7-3.606a.63.63,0,0,0-.228-.815L15.46,11.29a.63.63,0,0,0-.779.088l-1.41,1.411a.626.626,0,0,1-.524.18A7.372,7.372,0,0,1,8.561,10.743Z"
                            transform="translate(-0.997 -0.059)"
                            fill={color} />
                        <path
                            id="Icon_ionic-ios-star"
                            data-name="Icon ionic-ios-star"
                            d="M11.792,6.563H8.535L7.546,3.61a.359.359,0,0,0-.673,0l-.99,2.953H2.6a.355.355,0,0,0-.354.354.26.26,0,0,0,.007.06.34.34,0,0,0,.148.25L5.082,9.113,4.054,12.1a.355.355,0,0,0,.122.4.343.343,0,0,0,.2.086.434.434,0,0,0,.221-.08l2.612-1.862,2.612,1.862a.415.415,0,0,0,.221.08.318.318,0,0,0,.2-.086.351.351,0,0,0,.122-.4L9.335,9.113l2.654-1.9.064-.055a.339.339,0,0,0-.261-.591Z"
                            transform="translate(6.875 -3.8)"
                            fill={color} />
                    </g>
                </g>
            </svg>
        )}
    </Icon>
);