import moment from "moment";

function isInt(n) {
    return n % 1 === 0;
}

export const formatGraphData = graph => {
    var format = "D MMM";

    if (graph.period === "hour") format = "H:00"

    for (const obj of graph.results) {
        const m = moment(obj.time);
        obj.time = m.format(format)
    }
    return graph.results
}

export const formatTime = seconds => {
    seconds = (seconds) ? seconds : 0;
    return moment.utc(seconds * 1000).format("HH:mm:ss");
}

export const formatData = stats => {
    if (stats.total_cost)
        stats.total_cost = parseFloat(stats.total_cost)

    for (const k in stats) {
        if (stats[k] === null)
            stats[k] = 0

        if (typeof(stats[k]) === "number" && !isInt(stats[k]))
            stats[k] = Math.ceil(stats[k] * 100) / 100
    }
    return stats
}
