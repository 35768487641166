import React, { FC, ReactNode, useMemo, useState } from "react";
import { Button, Dropdown } from "../../../components";
import {
  LabelOutlineIcon,
  PlusCircleIcon,
} from '../../../components/icons';
import { FilterModel } from "../../../utils/types";

export interface IDashboardFiltersProps {
  filters: FilterModel[];
  onChange(filters: FilterModel[]): void;
  categories: any[];
  children?: ReactNode;
}

export const DashboardFilters: FC<IDashboardFiltersProps> = ({
  filters,
  onChange,
  categories,
  children,
}) => {
  const [activeFilter, setActiveFilter] = useState<string>();

  const remainCategories = useMemo(() => categories.filter((item) => filters.every((f) => f.category !== item.type)), [filters]);

  const onAddFilter = (category) => {
    onChange([
      ...filters,
      new FilterModel({
        category: category.type,
        text: '',
        value: null,
      }),
    ]);
    setTimeout(() => {
      setActiveFilter(category.type);
    }, 500);
  };

  const onSelectFilter = (filter: FilterModel) => {
    onChange(filters.map((item) => (
      item.category === filter.category ? new FilterModel(filter) : item
    )));
    setActiveFilter(undefined);
  };

  const onDeleteFilter = (filter: FilterModel) => {
    onChange(filters.filter((item) => item.category !== filter.category));
    setActiveFilter(undefined);
  };

  return (
    <div className="flex items-center flex-wrap flex-shrink">
      <span className="text-sm mr-2 mb-2">Filter data:</span>
      {filters.map((filter, i) => {
        if (filter.locked) {
          return (
            <React.Fragment key={filter.category}>
              {i > 0 && (
                <span className="text-xs mx-2 mb-2">And</span>
              )}
              <Button color="primary" className="text-xs !normal-case font-semibold rounded-full px-4 shadow-md !cursor-default mb-2">
                {filter.text}
              </Button>
            </React.Fragment>
          );
        }

        const category = categories.find((item) => item.type === filter.category);
        return (
          <React.Fragment key={filter.category}>
            {i > 0 && (
              <span className="text-xs mx-2 mb-2">And</span>
            )}
            <category.component
              title={category.title}
              key={filter.category}
              category={filter.category}
              value={filter}
              opened={filter.category === activeFilter}
              onChange={onSelectFilter}
              onDelete={onDeleteFilter}
              {...category?.args}
            />
          </React.Fragment>
        );
      })}
      {remainCategories.length > 0 && (
        <>
          <span className="text-xs mx-2 mb-2">And</span>
          <Dropdown
            key={remainCategories.length}
            className="mb-2"
            text="Add a Filter"
            buttonProps={{
              className: 'text-xs font-semibold rounded-3xl hover:!bg-primary hover:text-white',
              color: 'primary-o',
              leftIcon: <PlusCircleIcon className="fill-current" color="current" size={20} />,
            }}
            dropdownClass="w-max max-h-60 rounded-bl-md rounded-br-md px-3 pt-2"
          >
            {remainCategories.map((item) => (
              <div
                key={item.type}
                className="flex items-center cursor-pointer mb-2"
                onClick={() => onAddFilter(item)}
              >
                <div className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-primary bg-opacity-24 rounded-md">
                  {item.icon({ color: 'primary', size: item.iconSize })}
                </div>
                <span className="min-w-fit text-primary text-15p font-medium ml-2">{item.title}</span>
              </div>
            ))}
          </Dropdown>
        </>
      )}

      <div className="mr-auto" />

      {children}
    </div>
  );
};
