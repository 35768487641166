import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classnames from "classnames";
import {
  Avatar,
  DataTable,
  IDataTableColumn,
  TextField,
} from "../../../components";
import { EditIcon, PlusIcon, SearchIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { getActiveOrganization } from "../../../redux/selectors";
import { ToastService, UserService } from "../../../services";
import { UserModel } from "../../../utils/types";
import { ROLE } from "../../../utils/enums";
import Tooltip from "@mui/material/Tooltip";
import { debounce } from "lodash";

const Users = () => {
  const organization = useSelector(getActiveOrganization);
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [users, setUsers] = useState<UserModel[]>([]);

  const debouncedSetFilter = useCallback(
    debounce((searchEmail) => setFilterSearch(searchEmail), 500),
    []
  );

  useEffect(() => {
    if (!organization) {
      setUsers([]);
      return;
    }

    UserService.search(organization.uuid, filterSearch).then(
      (data) => {
        setUsers(data);
      },
      (err) => {
        setUsers([]);
        ToastService.showHttpError(err, "Loading users failed");
      }
    );
  }, [organization, filterSearch]);

  const columns = useMemo<IDataTableColumn<UserModel>[]>(
    () => [
      {
        title: "Name",
        field: "name",
        // headerClass: "!pl-19",
        tooltip: { title: "The user’s name" },
        render(row: UserModel) {
          return (
            <div className="flex items-center">
              <span>
                {row.first_name} {row.last_name}
              </span>
            </div>
          );
        },
      },
      {
        title: "Email",
        field: "email",
        tooltip: { title: "The user’s email address" },
      },
      {
        title: "Role",
        field: "role",
        tooltip: { title: "The Role assigned to the user" },
        render(row: UserModel) {
          return (
            <div
              className={classnames(
                "bg-blue-100 rounded-lg px-2 py-2 inline-block",
                "text-blue-600 font-bold text-xs"
              )}
            >
              <span>
                {row.relationship.roles.includes(ROLE.ADMIN)
                  ? "Admin"
                  : row.relationship.roles.includes(ROLE.USER)
                  ? "User"
                  : "View Only"}
              </span>
            </div>
          );
        },
      },
      {
        title: "Last Login",
        field: "last_login",
        tooltip: { title: "When last was the last time the user logged in" },
      },
      {
        render(row: UserModel) {
          return (
            <Tooltip
              data-cy="edit-user"
              title="Edit User"
              arrow
              placement="left"
            >
              <Link
                className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-auto cursor-pointer"
                to={`${ROUTES.SETTINGS.USERS.PREFIX}/${row.uuid}/edit`}
              >
                <EditIcon color="blue" size={22} />
              </Link>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const onChange = (value) => {
    setSearch(value);
    debouncedSetFilter(value);
  };
  return (
    <>
      <div className="relative h-full flex flex-col px-7 py-9">
        <div>
          <TextField
            size="sm"
            fullWidth
            className="!rounded-full !bg-white shadow-md"
            containerClass="w-80 ml-auto"
            value={search}
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search"
            clearable
            onChange={onChange}
          />
        </div>

        <DataTable
          wrapperClass="h-0 flex-grow mt-4"
          columns={columns}
          data={users}
          size="xs"
          stickyHeader
          stripped
          headerCellClass="!text-sm !px-8"
          cellClass="!text-sm !px-8"
        />

        <Link
          data-cy="invite-new-user"
          className={classnames(
            "plus-action fixed bottom-5 right-5 w-13.5 hover:w-60 h-13.5 flex items-center overflow-hidden px-3",
            "bg-primary text-white text-lg font-medium uppercase whitespace-nowrap rounded-full shadow-md transition-all cursor-pointer"
          )}
          to={ROUTES.SETTINGS.USERS.CREATE}
        >
          <PlusIcon color="white" size={30} />
          <span className="ml-3">Invite New User</span>
        </Link>
      </div>
    </>
  );
};

export default Users;
