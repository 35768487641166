import 'chart.js/auto';
import React, { FC } from "react";
import { Line } from "react-chartjs-2";

interface ILineGraphProps {
    labels?: Array<string>;
    data?: Array<any>;
    title?: string;
}

export const LineGraph: FC<ILineGraphProps> = ({
    labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    data = [],
    title = "Default"
}) => {

    const [graphTitle, setGraphTitle] = React.useState<string>()
    const [graphData, setGraphData] = React.useState<Array<any>>([]);

    React.useEffect(() => {
        if (!data) return;
        const dataSet: Array<object> = [];
        data?.map((set) => {
            if (!set.data.length) return {}
            dataSet.push(
                {
                    label: set.label,
                    data: set.data,
                    borderColor: set.color,
                    backgroundColor: set.color,
                    tension: 0,
                    fill: false,
                    pointRadius: 0,
                    borderWidth: 3,
                },
            )
            return dataSet;
        })

        setGraphData(dataSet)
        setGraphTitle(title)
    }, [data])

    return (
        <>
            {!graphData?.length &&
                <div className='flex justify-center items-center h-full w-full'>
                    <p className='text-sm'>No data</p>
                </div>
            }
            {graphData &&
                <Line
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: 10,
                        },
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        plugins: {
                            tooltip: {
                                enabled: true,
                                intersect: false,
                                mode: 'nearest',
                            },
                            datalabels: {
                                display: false,
                            },
                            title: {
                                display: true,
                                text: graphTitle,
                                position: 'top',
                                align: 'start',
                                fullSize: true,
                            },
                            legend: {
                                align: 'end',
                                position: "top",
                                display: false,
                                labels: {
                                    usePointStyle: true,
                                    pointStyle: 'circle',
                                    boxWidth: 15,
                                    boxHeight: 15,
                                }
                            }
                        }
                    }}
                    data={{
                        labels: labels,
                        datasets: graphData,
                    }} />
            }
        </>
    )
}
