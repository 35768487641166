import { HttpService } from "./http.service";
import { formatPaginatedResult, trimObject } from "../utils/helpers";

export class StatsService {

  static stats(product, query = {}, showSpinner = true) {
    return HttpService.post(`/stats/${product}`, trimObject(query, [undefined, null]), {}, showSpinner)
  }

  static graph(product, query = {}, showSpinner = true) {
    return HttpService.post(`/graph/${product}`, trimObject(query, [undefined, null]), {}, showSpinner)
  }
}
