import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../constants";
import { WhatsappLeadsDashboard } from "./WhatsappLeads";
import { MessagesDashboard } from "./Messages";
import { CallsDashboard } from "./Calls";

const DashboardRouting = () => (
  <Switch>
    <Route path={ROUTES.DASH.WHATSAPP_LEADS} component={WhatsappLeadsDashboard} />
    <Route path={ROUTES.DASH.MESSAGES} component={MessagesDashboard} />
    <Route path={ROUTES.DASH.CALLS} component={CallsDashboard} />
    <Redirect to={ROUTES.DASH.INDEX} />
  </Switch>
);

export default DashboardRouting;
