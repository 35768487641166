import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const MessageFailedIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={14.125} baseHeight={14.125} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 14.125 14.125">
        <path
          id="Icon_material-sms-failed"
          data-name="Icon material-sms-failed"
          d="M15.712,3H4.412A1.411,1.411,0,0,0,3.007,4.412L3,17.125,5.825,14.3h9.887a1.417,1.417,0,0,0,1.413-1.412V4.412A1.417,1.417,0,0,0,15.712,3Zm-4.944,8.475H9.356V10.063h1.412Zm0-2.825H9.356V5.825h1.412Z"
          transform="translate(-3 -3)"
          fill={color} />
      </svg>
    )}
  </Icon>
);
