import { TITLE } from "../../constants";
import { CHAT_LOG_STATUS } from "../../utils/enums";

interface IFilterModel {
  category: string;
  text: string;
  value: any;
  locked?: boolean;
}

export class FilterModel {
  category: string;
  text: string;
  value: any;
  locked?: boolean;

  constructor(init: IFilterModel) {
    Object.assign(this, init)
  }

  toString(): string {
    switch (this.category) {
      case "range":
        return `${this.text}~${this.value.startDate}~${this.value.endDate}`
      default:
        return this.value
    }
  }

  static getCategory(list: FilterModel[], category: string) {
    return list.find(x => x.category === category)
  }

  static moveRangeToHead(list: FilterModel[]) {
    return [
      FilterModel.getCategory(list, "range"),
      ...list.filter(x => x.category !== "range")
    ]
  }
}

export class ChatLogParsingError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ChatLogParsingError.prototype);
  }
}

export class ValidationFilterModel extends FilterModel {
  constructor(init: IFilterModel) {
    super(init)
    this.validate()
  }

  static parse(category: string, value: string): ValidationFilterModel {
    var parsed_value: any = value
    var text: string = `${TITLE[category]}: “${value}”`

    switch (category) {
      case 'range':
        const split_values = value.split('~')

        text = split_values[0]
        parsed_value = { startDate: split_values[1], endDate: split_values[2] }
        break
    }

    return new this({
      category: category,
      text: text,
      value: parsed_value,
    })
  }

  validate() {}
}

export class ChatLogFilterModel extends ValidationFilterModel {
  static parse(category: string, value: string): ChatLogFilterModel {
    return super.parse(category, value) as ChatLogFilterModel;
  }

  validate() {
    switch (this.category) {
      case "status":
        for (var status of this.value.split(",")) {
          if (!CHAT_LOG_STATUS[status]) {
            throw new Error("Invalid Status.")
          }
        }
        break
    }
  }
}
