import React, { FC } from "react";
import { IIconProps } from "./types";
import { Icon } from "./Icon";

export const PhoneCallUnansweredIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={20.38} baseHeight={15.159} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 20.38 15.159">
        <path
          id="Icon_material-phone-missed"
          data-name="Icon material-phone-missed"
          d="M5.517,6.622l4.668,4.668,5.942-5.942L15.278,4.5,10.186,9.593l-3.82-3.82H9.337V4.5H4.244V9.593H5.517ZM20.125,16.1a14.443,14.443,0,0,0-19.879,0,.861.861,0,0,0,0,1.205l2.105,2.105a.846.846,0,0,0,.6.246.871.871,0,0,0,.594-.238,9.692,9.692,0,0,1,2.258-1.57.846.846,0,0,0,.475-.764V14.456a12.413,12.413,0,0,1,3.9-.62,12.656,12.656,0,0,1,3.9.611v2.631a.855.855,0,0,0,.475.764,10.006,10.006,0,0,1,2.266,1.57.834.834,0,0,0,.594.238.846.846,0,0,0,.6-.246L20.133,17.3a.846.846,0,0,0,.246-.6.833.833,0,0,0-.255-.594Z"
          transform="translate(0 -4.5)"
          fill={color} />
      </svg>

    )}
  </Icon>
);
