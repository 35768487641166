import React, { memo } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { AuthRoute, TotpRoute, Preloader } from "./providers";
import { MessageBoxContainer, SpinnerContainer, ToastContainer } from "./components";
import { getToken, getActiveOrganization } from "./redux/selectors";

import FullLayout from "./layouts/FullLayout";
import { ROUTES } from "./constants";
import { AuthService, setHeaders } from "./services";

import Login from "./pages/Auth/Login";
import ConfirmEmail from "./pages/Auth/ConfirmEmail";
import ActivateAccount from "./pages/Auth/ActivateAccount";
import ResetPassword from "./pages/Auth/ResetPassword";

import ConfirmTotp from "./pages/Auth/ConfirmTotp";
import OnboardRouting from "./pages/Onboard/routing";
import CallsRouting from "./pages/Calls/routing";
import MessagesRouting from "./pages/Messages/routing";
import NumbersRouting from "./pages/Numbers/routing";
import DeveloperRouting from "./pages/Developer/routing";
import SettingsRouting from "./pages/Settings/routing";
import WhatsappRouting from "./pages/Whatsapp/routing";
import Options from "./pages/Options";
import Dashboard from "./pages/Dashboard";
import DashboardRouting from "./pages/Dashboard/routing";

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

AuthService.csrf().then(({ csrf }) => {
  setHeaders({ "X-CSRFToken": csrf })
})

const Main = () => {
  const organization = useSelector(getActiveOrganization);

  const default_page = ROUTES.DASH.INDEX

  return (
    <Preloader>
      <Route path={ROUTES.ONBOARD.PREFIX} component={OnboardRouting} />
      <FullLayout>
        <Switch>
          <Redirect path={ROUTES.DASHBOARD} to={default_page} />
          <Route path={ROUTES.CALLS.PREFIX} component={CallsRouting} />
          <Route path={ROUTES.MESSAGES.PREFIX} component={MessagesRouting} />
          <Route path={ROUTES.NUMBERS.PREFIX} component={NumbersRouting} />
          <Route path={ROUTES.DEVELOPER.PREFIX} component={DeveloperRouting} />
          <Route path={ROUTES.WHATSAPP.PREFIX} component={WhatsappRouting} />
          <Route path={ROUTES.SETTINGS.PREFIX} component={SettingsRouting} />
          {/*<Route path={ROUTES.OPTIONS.PREFIX} component={Options} />*/}
          <Route path={ROUTES.DASH.PREFIX} component={DashboardRouting} />
          <Redirect to={ROUTES.DASHBOARD} />
        </Switch>
      </FullLayout>
    </Preloader>
  )
}

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path={ROUTES.AUTH.LOGIN} component={Login} />,
          <Route path={ROUTES.AUTH.CONFIRM_EMAIL} component={ConfirmEmail} />,
          <Route path={ROUTES.AUTH.VERIFY_TOKEN} component={ActivateAccount} />,
          <Route path={ROUTES.AUTH.RESET_PASSWORD} component={ResetPassword} />,
          <AuthRoute path={ROUTES.AUTH.CONFIRM_TOTP} component={ConfirmTotp} />

          <TotpRoute component={Main} />
        </Switch>
      </Router>

      <ToastContainer />
      <MessageBoxContainer />
      <SpinnerContainer />
    </ThemeProvider>
  );
}

export default App;
