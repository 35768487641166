import 'chart.js/auto';
import React, { FC } from "react";
import { Doughnut } from "react-chartjs-2";

interface IDoughnutGraphProps {
    data?: Array<any>;
    title?: string;
}

export const DoughnutGraph: FC<IDoughnutGraphProps> = ({
    data = [],
    title = "Default"
}) => {

    const [graphTitle, setGraphTitle] = React.useState<string>();
    const [graphTotal, setGraphTotal] = React.useState<number>(0);
    const [graphLabels, setGraphLabels] = React.useState<Array<string>>();
    const [graphData, setGraphData] = React.useState<Array<number>>();

    React.useEffect(() => {
        const filtered = data.filter(x => x.value)
        setGraphTotal(filtered.reduce((partialSum, x) => partialSum + x.value, 0))
        setGraphLabels(filtered.map(x => x.label))
        setGraphData(filtered.map(x => x.value))
        setGraphTitle(title)
    }, [data])

    const percentage = value => {
        return ((value / graphTotal) * 100);
    }

    return (
        <>
            {!graphData?.length &&
                <div className='flex justify-center items-center h-full w-full'>
                    <p className='text-sm'>No data</p>
                </div>
            }
            {graphData &&
                <Doughnut
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                            padding: 10,
                        },
                        plugins: {
                            tooltip: {
                                enabled: true,
                            },
                            datalabels: {
                                color: '#000000',
                                display: context => {
                                    var dataset = context.dataset;
                                    var value = dataset.data[context.dataIndex];
                                    return percentage(value) > 1;
                                },
                                formatter: value => {
                                    return `${percentage(value).toFixed(0)}%`
                                }
                            },
                            title: {
                                display: true,
                                text: graphTitle,
                                position: 'top',
                                align: 'start',
                                fullSize: true,
                            },
                            legend: {
                                position: "right",
                                labels: {
                                    usePointStyle: true,
                                    pointStyle: 'circle',
                                    boxWidth: 20,
                                    boxHeight: 20,
                                }
                            }
                        }
                    }}
                    data={{
                        labels: graphLabels,
                        datasets: [
                            {
                                data: graphData,
                                backgroundColor: [
                                    '#307CD8',
                                    '#EAB73E',
                                    '#EAAA4D',
                                    '#0F1C32',
                                    '#90D5EB',
                                    '#D0D0D0',
                                    '#9FBDE0',
                                    '#4656F1',
                                ],
                                borderWidth: 0,
                            },
                        ],
                    }} />
            }
        </>
    )
}
