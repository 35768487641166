import React, { FC, ReactElement } from "react";
import { Card } from "../Card";
import classNames from "classnames";
import { WithTooltipIcon } from "../WithTooltipIcon";

interface IStatisticsTileProps {
    className?: string;
    value?: any;
    valueClass?: string;
    label?: string;
    labelClass?: string;
    labelPlacement?: 'top' | 'bottom';
    color?: string;
    backdropClass?: string;
    tooltip?: string;
    tooltipIcon?: ReactElement;
    textAlign?: string;
    onClick?: () => void;
}

export const StatisticsTile: FC<IStatisticsTileProps> = ({
    className,
    value,
    valueClass = '',
    label = '',
    labelClass = '',
    labelPlacement = 'bottom',
    color = 'gray',
    backdropClass = 'bg-gray-300',
    tooltip,
    tooltipIcon,
    children,
    textAlign = "text-left",
    onClick,
}) => {
    return (
        <Card
            onClick={onClick}
            className={classNames(className, "min-w-[14rem] mr-4 !p-2")}
        >
            {tooltip &&
                <WithTooltipIcon
                    className="justify-end"
                    placement="bottom"
                    tooltip={tooltip}
                    icon={tooltipIcon}
                    iconSize={15}
                />
            }
            <div className={classNames("flex flex-row p-2", !tooltip && "mt-4")}>
                {children &&
                    <div className={classNames("rounded-2xl h-[60px] w-[60px] grid place-content-center p-4", backdropClass)}>
                        {children}
                    </div>
                }
                <div className={classNames("flex flex-col mx-2 whitespace-nowrap", textAlign)}>
                    {labelPlacement === 'top' &&
                        <p className={labelClass}>{label}</p>
                    }
                    <p className={classNames(valueClass, "font-bold text-xl")} style={{ color: `${color}` }}>{value || '-'}</p>
                    {labelPlacement === 'bottom' &&
                        <p className={labelClass}>{label}</p>
                    }
                </div>
            </div>
        </Card>
    )
}
